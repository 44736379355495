if (window) {
  const nav = document.querySelector("#nav");
  const navToggle = document.querySelector("#nav-toggle");
  const navInner = document.querySelector("#nav-inner");
  const isDesktop = window.innerWidth > 767;

  const navItemSelector = isDesktop
    ? ".nav__list--root > .nav__item"
    : ".nav__item";

  const closeSubmenu = async () => {
    nav.querySelectorAll(".nav__submenu--open").forEach((openSubnav) => {
      openSubnav.classList.remove("nav__submenu--open");
    });
  };

  if (navToggle) {
    navToggle.addEventListener("click", () => {
      navInner.classList.toggle("nav__inner--open");
      navToggle.classList.toggle("nav__toggle--active");
    });
  }

  //Check all nav items
  if (navInner) {
    navInner.querySelectorAll(navItemSelector).forEach((item) => {
      const navLink = item.querySelector("a");
      const navSubmenu = item.querySelector(".nav__submenu");

      //Add sublink class
      if (navSubmenu) {
        //Append Submenu Link

        const subMenuClose = document.createElement("div");
        subMenuClose.classList.add("nav__submenu-close");
        // subMenuClose.innerHTML = isDesktop ? "Close" : "Back";

        const subMenuHeadline = document.createElement(
          navLink.href ? "a" : "div"
        );
        subMenuHeadline.classList.add("nav__submenu-headline");
        subMenuHeadline.innerHTML = navLink.innerHTML;
        subMenuHeadline.href = navLink.href;

        navSubmenu.prepend(subMenuHeadline);
        navSubmenu.prepend(subMenuClose);

        navSubmenu
          .querySelector(".nav__submenu-close")
          .addEventListener("click", () => {
            if (isDesktop) {
              closeSubmenu();
            } else {
              navSubmenu.classList.remove("nav__submenu--open");
            }
          });

        item.classList.add("nav__item--has-submenu");

        navLink.addEventListener("click", (e) => {
          e.preventDefault();
          if (isDesktop) {
            closeSubmenu();
          }

          navSubmenu.classList.add("nav__submenu--open");

          if (!isDesktop) {
            console.log(navSubmenu.closest(".nav__submenu"));
            navSubmenu
              .closest(".nav__submenu")
              .classList.add("nav__submenu--open");
          }
        });
      }

      //set active class
      if (navLink.pathname === window.location.pathname) {
        item.classList.add("nav__item--active");
      }
    });
  }
}
